<template>
  <div class="otherPay-container">
    <!-- <Top></Top> -->
      <div class="otherPay-box-top">
         <div class="otherPay-box-top-div">
           <img :src="require('/static/images/net.png')" alt="">
           <span>订单支付</span>
         </div>
     </div>
     <div class="otherPay-middle">
         <div class="otherPay-middle-top">
             <img :src="require('/static/images/2.png')" alt="">
             <span>海底捞</span>
         </div>
         <div class="otherPay-middle-title">商标注册类别</div>
         <div class="otherPay-middle-type">
             <div class="otherPay-middle-bigtype" v-for="(v,k) in 2">
                 <div class="otherPay-middle-bigtype-onecate">
                     <i class="el-icon-arrow-up"></i>
                     <span>第25类   广告销售</span>
                     <span>￥3000.00</span>
                 </div>
                 <div class="otherPay-middle-bigtype-twocate" v-for="(v,k) in 2">
                     <div class="otherPay-middle-bigtype-second">2035  货物</div>
                     <div class="otherPay-middle-bigtype-detail">
                         <span v-for="(v,k) in 20">203211  提他人推销</span>
                     </div>
                 </div>
             </div>
         </div>
         <div class="otherPay-midddle-invoce">
             <div>发票信息</div>
             <div>
                 <span>公司名称</span>
                 <span>杭州之和科技有限公司</span>
             </div>
             <div>
                 <span>税&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</span>
                 <span>2145644545454545</span>
             </div>
             <div>
                 <span>详细地址</span>
                 <span>杭州市滨江区常信路聚光中心3座2602</span>
             </div>
             <div>
                 <span>公司电话</span>
                 <span>020-3562124</span>
             </div>
         </div>
         <div class="otherPay-middle-paytype" v-show="successshow">
             <div class="otherPay-middle-paytype-title">
                 <span>支付方式</span>
                 <a href="">预览协议</a>
             </div>
             <div class="otherPay-middle-chose">
                 <div :class="active">
                     <div class="div1" @click="changePay('lv')">
                         <div>
                             <div></div>
                         </div>
                         <div>
                             <img :src="require('/static/images/lv.png')" alt="">
                         </div>
                     </div>
                     <div class="div2" @click="changePay('bao')">
                         <div>
                             <div></div>
                         </div>
                         <div>
                             <img :src="require('/static/images/bao.png')" alt="">
                         </div>
                     </div>
                     <div class="div3" @click="changePay('wei')">
                         <div>
                             <div></div>
                         </div>
                         <div>
                             <img :src="require('/static/images/wei.png')" alt="">
                         </div>
                     </div>
                 </div>
             </div>
             <div class="otherPay-middle-infor" v-show="active == 'lv'">
                <div class="otherPay-middle-zhuan">
                    <span>转账公司名称</span>
                    <input type="text" placeholder="请输入转账公司名称">
                </div>
                <div class="otherPay-middle-count">
                    <div>打款账户信息</div>
                    <div>公司名称：知昇（上海）知识产权代理有限公司</div>
                    <div>开户行：上海浦东发展银行松江支行</div>
                    <div>账号：98080078801500002499<span class="tag-read" :data-clipboard-text="98080078801500002499" @click="copy">复制</span></div>
                    <div>越快支付，越早为您办理业务</div>
                </div>
             </div>
             <div class="otherPay-middle-totalpay">
                <div class="otherPay-middle-totalpay-price">
                    <div>应付总额：<span>￥2000</span></div>
                    <div>共计<span>4</span>件</div>
                </div>
                <div class="otherPay-middle-totalpay-do">确认付款</div>
            </div> 
         </div>
         <div v-show="!successshow">
             <div class="otherPay-middle-paytype-title success">
                 <span>支付信息</span>
                 <a href="">预览协议</a>
             </div>
             <div class="otherPay-middle-paytype-success">
                 <div>
                     支付方式<span>支付宝</span>
                 </div>
                 <div>
                     支付金额<span>￥500</span>
                 </div>
                 <div>
                     共计<span>4</span>件
                 </div>
             </div>
         </div>
     </div>
     <!-- <Foot></Foot> -->
     <div class="otherPay-window" v-show="windowshow">
         <div class="otherPay-little-window"> 
             <img :src="require('/static/images/orderdetailcha.png')" alt="">
             <div>
                 <div class="otherPay-little-window-info">打款账户信息</div>
                 <div class="otherPay-little-window-countinfo">
                     <div>公司名称：知昇（上海）知识产权代理有限公司</div>
                     <div>开户行：上海浦东发展银行松江支行</div>
                     <div>账号：98080078801500002499</div>
                 </div>
                 <div id="otherPay-little-window-tip">越快支付，越早为您办理业务</div>
             </div>
         </div>
     </div>
  </div>    
</template>

<script>
// import Top from '../ui/top.vue';
// import Foot from '../ui/foot.vue';
import Clipboard from 'clipboard';
export default {
  name: 'otherPay',
  data(){
    return{ 
     active:'lv',
     windowshow:false,
     successshow:true
    }
  },
  watch:{
           

  },
  components: {
    //    Top,
    //   Foot
  },
  mounted(){
    this.getdata()
  },
  methods:{
      tip(type,msg){
                return this.$message({
                    type:type,
                    message:msg,
                    showClose:true,
                    duration:1500
                })
            },
    changePay(val){
        this.active = val;
    },
    copy() {
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
      this.tip('success','复制成功')
      // 释放内存
      clipboard.destroy()
      })
      clipboard.on('error', e => {
      // 不支持复制
      console.log('该浏览器不支持自动复制')
      // 释放内存
      clipboard.destroy()
      })
      },
      getdata(){
          this.$http.get(this.GLOBAL.new_url+'/transaction/service/open/getOrderInfoByTradeCode',{
              params:{
                tradeCode:this.$route.query.id
              },
              headers:{
                        "AuthToken":'eyJhbGciOiJSUzI1NiIsImtpZCI6IkxmZ3V1ZjFMa3hSS1BsVzB1NTZuRGkwVk5SMkpvR3lvQUFFUzl1TUxVOXcifQ.eyJqdGkiOiJja2pqa2FfbllsQW80Y0RlUmtKMlRnIiwiaWF0IjoxNjE4Mjk4MzU3LCJleHAiOjE4MzQyOTgzNTcsIm5iZiI6MTYxODI5ODI5NywiWC1FbXAtQ29kZSI6IkVNUDIwMjEwMTE5MjAzNTMwMTk0MDA4IiwiWC1FbXAtTmFtZSI6Iui2hee6p-euoeeQhuWRmCIsInVwZGF0ZVRpbWUiOjE2MTgyOTgzNTcwMDN9.NDhA0h4W9iT513DYoRAtK2JVbizzh2nBoGjIxbFK-EhgBO_2eM__5GVip1Fv_qwoKYeMQD_j99BHw9TfjDym0SkKsvMIgVYRPx-ZRJbV4J9y3OX-KzlQ0gytjJgEr3CbqamhDbgu10dOSxTIcOHfNwFXVDh0Xiv4TJJTd-QudFemtKDi8YvKCxOam1Oq42I7CagCkctOKwg39XZ-FLquqnOpK3y2h7QYJoQys8ClD4BVG49T_Hi2yjY_Yqvy0jWZZZxDOaK7rp--vQMb18qXXKn0sn0f70Lai3kz0qcwyEWVXIViwg-Waq-A7L1BAuqewoi6cI7tKGuGzTefQsO2xw',
                        'X-Request-Origin':'XCX'
                    }
          }).then(res=>{
              console.log(res)
          })
      }
  }
}

</script>

<style scoped>
    .otherPay-box-top{
    width: 100%;
    height: 188px;
    background-color: #f8f8f8;
  }
  .otherPay-box-top-div{
    width: 1040px;
    height: 188px;
    margin: 0 auto;
    line-height: 188px;
  }
  .otherPay-box-top-div>img{
    vertical-align: middle;
    margin-right: 25px;
  }
  .otherPay-box-top-div>span{
    font-size: 36px;
    font-weight: 600;
    color: #111111;
    vertical-align: middle;
  }
  .otherPay-middle{
      width: 1200px;
      padding-top: 40px;
      margin: 0 auto 80px;
  }
  .otherPay-middle-top{
      margin-bottom: 40px;
  }
  .otherPay-middle-top>img{
      width: 70px;
      height: 70px;
      vertical-align: middle;
      margin-right: 22px;
  }
  .otherPay-middle-top>span{
      font-size: 18px;
      color: #000000;
  }
  .otherPay-middle-title{
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
  }
  .otherPay-middle-type{
    margin-bottom: 50px;
    background: #f8f8f8;
  }
  .otherPay-middle-bigtype{
      border-bottom: 1px solid #d8d8d8;
      padding: 20px;
  }
  .el-icon-arrow-up{
      cursor: pointer;
  }
  .otherPay-middle-bigtype-onecate>span{
      font-size: 14px;
      color: #333333;
      margin-left: 10px;
  }
  .otherPay-middle-bigtype-onecate>span:nth-of-type(2){
      color: #F96009;
      margin-left: 946px;
  }
  .otherPay-middle-bigtype-twocate{
      margin: 20px 0 0 70px;
  }
  .otherPay-middle-bigtype-second{
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
  }
  .otherPay-middle-bigtype-detail{
      width: 1090px;
  }
   .otherPay-middle-bigtype-detail>span{
       display: inline-block;
       padding: 0 9px;
       height: 22px;
       border: 1px solid #d8d8d8;
       margin: 0 10px 4px 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color:#A1A1A1;
   }
   .otherPay-midddle-invoce{
       margin-bottom: 50px;
   }
   .otherPay-midddle-invoce>div{
       margin-bottom: 14px;
   }
   .otherPay-midddle-invoce>div:nth-of-type(1){
        font-size: 18px;
        margin-bottom: 16px;
        font-weight: bold;
        color: #000000;
   }
   .otherPay-midddle-invoce>div>span{
        font-size: 14px;
        font-weight: 400;
        color: #000000;
   }
   .otherPay-midddle-invoce>div>span:nth-of-type(2){
       margin-left: 25px;
   }
   .otherPay-middle-paytype-title{
       margin-bottom: 20px;
       height: 28px;
       line-height: 28px;
   }
  .otherPay-middle-paytype-title>span{
    font-size: 18px;
    margin-right: 16px;
    font-weight: bold;
    color: #000000;
  }
  .otherPay-middle-paytype-title>a{
      text-decoration: none;
      font-size: 12px;
      font-weight: 400;
      color: #000000;
  }
  .otherPay-middle-chose{
      margin-bottom: 24px;
  }
  .otherPay-middle-chose>div{
      height: 80px;
  }
  .otherPay-middle-chose>div>div{
      float: left;
      width: 208px;
      margin-right: 24px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #F96009;
      opacity: 0.3;
  }
  .otherPay-middle-chose>div>div>div{
      float: left;
  }
  .otherPay-middle-chose>div>div>div:nth-of-type(1){
      width: 14px;
      height: 14px;
      border: 1px solid #F96009;
      border-radius: 50%;
      position: relative;
      margin: 32px 12px 32px 38px;
  }
  .otherPay-middle-chose>div>div>div:nth-of-type(1)>div{
      width: 8px;
      height: 8px;
      position: absolute;
      text-align: center;
      background: #F96009;
      border-radius: 50%;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
  }
  .otherPay-middle-chose>div>div>div:nth-of-type(2){
      margin-top: 23px;
  }
  .otherPay-middle-chose>div>div>div:nth-of-type(2)>img{
      height: 34px;
      width: 120px;
  }
  .lv .div1,.bao .div2,.wei .div3{
      opacity: 1;
  }
  .otherPay-middle-infor{
      margin-bottom: 50px;
  }
  .otherPay-middle-zhuan{
      margin-bottom: 18px;
  }
  .otherPay-middle-zhuan>span{
      font-size: 14px;
       margin-right: 14px;
       font-weight: 400;
       color: #000000;
  }
  .otherPay-middle-zhuan>input{
    width: 368px;
    outline: none;
    padding-left: 14px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #D8D8D8;
  }
  .otherPay-middle-count>div{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
  .otherPay-middle-count>div:nth-of-type(5){
      color: #F96009;
  }
  .otherPay-middle-count>div>span{
      display:inline-block;
      width: 48px;
      height: 18px;
      background: #D8D8D8;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      line-height: 18px;
      color: #000000;
      margin-left: 15px;
      cursor: pointer;
  }
  .otherPay-middle-totalpay{
      height: 54px;
  }
  .otherPay-middle-totalpay-price{
      float: left;
  }
  .otherPay-middle-totalpay-price>div:nth-of-type(1){
    font-size: 18px;
    color: #000000;
    margin-bottom: 9px;
  }
  .otherPay-middle-totalpay-price>div:nth-of-type(1)>span, .otherPay-middle-totalpay-price>div:nth-of-type(2)>span{
      color: #F96009;
  }
  .otherPay-middle-totalpay-price>div:nth-of-type(2){
      font-size: 14px;
  }
  .otherPay-middle-totalpay-do{
      float: right;
      width: 214px;
      height: 54px;
      background: #F96009;
      border-radius: 3px;
      cursor: pointer;
      line-height: 54px;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
  }
  .success{
      margin-bottom: 16px;
  }
  .otherPay-middle-paytype-success>div{
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
  }
   .otherPay-middle-paytype-success>div>span{
       margin-left: 20px;
       color: #F96009;
   }
   .otherPay-middle-paytype-success>div:nth-of-type(1)>span{
       color: #000000;
   }
   .otherPay-middle-paytype-success>div:nth-of-type(3)>span{
       margin-left: 0;
   }
   .otherPay-window{
       width: 100%;
       height: 100%;
       position: fixed;
       top: 0;
       left: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
   }
   .otherPay-little-window{
       width: 430px;
        height: 278px;
        background-color: #ffffff;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%)
   }
   .otherPay-little-window>img{
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
   }
   .otherPay-little-window>div{
       position: absolute;
       top: 40px;
       left: 20px;
   }
   .otherPay-little-window>div>div{
       width: 390px;
       text-align: center;
        font-size: 16px;
        font-weight: 400;
   }
   .otherPay-little-window-info{
       margin-bottom: 23px;
   }
   .otherPay-little-window-countinfo{
       width: 294px!important;
        height: 110px;
        background: #FAFAFA;
        padding: 20px 48px 0;
        margin-bottom: 16px;
   }
   .otherPay-little-window-countinfo>div{
       font-size: 14px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 10px;
        text-align: start;
   }
   #otherPay-little-window-tip{
       color: #F96009;
       font-size: 14px;
        font-weight: 400;
   }
</style>

